import React, { Fragment, useEffect, useState } from "react"

import Loadable from "@loadable/component"

import { Text, Div, Anchor, Button, Icon } from "atomize"

import { makeStyles } from "@material-ui/core/styles"

// import CircularProgress from "@material-ui/core/CircularProgress"

import ReactWebChat, {
  createStore,
  createDirectLine,
} from "botframework-webchat"

import "./chat.min.css"

const Dialog = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/Dialog")
)
const DialogTitle = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/DialogTitle")
)
const DialogContent = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/DialogContent")
)
const Tooltip = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/Tooltip")
)
const IconButton = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/core/IconButton")
)
const Close = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/icons/Close")
)
const Replay = Loadable(() =>
  import(/* webpackPrefetch: true */ "@material-ui/icons/Replay")
)

const useStyles = makeStyles(() => ({
  modalHeader: {
    borderBottom: "none",
    paddingRight: "24px",
    paddingBottom: "12px",
    paddingLeft: "24px",
    "@media (max-width: 768px)": {
      paddingTop: "12px",
      paddingRight: "12px",
    },
    "@media (min-width: 769px)": {
      paddingTop: "14px",
    },
    minHeight: "16.43px",
  },
  modalTitle: {
    // margin: "0",
    // lineHeight: "1.42857143",
  },
  modalCloseButton: {
    color: "#999999",
    "@media (max-width: 768px)": {
      marginTop: "auto",
    },
    // marginTop: '-6px',
    WebkitAppearance: "none",
    padding: "0 !important",
    cursor: "pointer",
    background: "0 0",
    border: "0",
    fontSize: "inherit",
    opacity: ".9",
    textShadow: "none",
    fontWeight: "700",
    lineHeight: "1",
    float: "right",
  },
  modalClose: {
    "@media (max-width: 768px)": {
      width: "30px",
      height: "30px",
    },
    "@media (min-width: 769px)": {
      width: "24px",
      height: "24px",
    },
  },
  modalBody: {
    backgroundColor: "white",
    height: "100%",
    paddingTop: "0px !important",
    position: "relative",
    "@media (max-width: 768px)": {
      paddingRight: "0px !important",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
      height: "100%",
    },
    "@media (min-width: 769px)": {
      paddingRight: "24px",
      paddingLeft: "24px",
      paddingBottom: "16px",
    },
  },
  modalChat: {
    borderRadius: "8px",
    height: "100%",
    "@media (max-width: 768px)": {
      width: "100% !important",
      margin: "0px !important",
      maxHeight: "100% !important",
      height: "100% !important",
    },
    "@media (max-height: 750px)": {
      margin: "0px !important",
      maxHeight: "100% !important",
      height: "100% !important",
    },
  },
}))

const token = "1NJ5m8GdtJo.xo7mBEbw-4xkLIxbjF49VL66DnuG4w-YuFF8FEMq2ak" // NAVI
const domain = "https://europe.directline.botframework.com/v3/directline" //NOTE: europe as region is vital for gdpr compliance to make sure conversation data is not leaving the EU
const botId = "byq2p8kgv7hxpk" // NAVI

const storeMiddleware = ({ dispatch }) => next => action => {
  // console.log(action)
  if (action.type === "DIRECT_LINE/INCOMING_ACTIVITY") {
    const { activity } = action.payload
    if (activity.type === "message") {
      // always enforce scrolling to new message (from user or bot)
      // setTimeout(() => {
      // let lastChild = document.querySelector(
      //   "ul.webchat__basic-transcript__transcript"
      // )?.lastChild
      let transscript = document.querySelector(
        "ul.webchat__basic-transcript__transcript"
      )
      if (transscript && transscript.lastElementChild) {
        let lastChild = transscript.lastElementChild.querySelector(
          ".ac-container"
        )
        if (lastChild) {
          lastChild.scrollIntoView({ behavior: "smooth", block: "start" })
        } else {
          setTimeout(() => {
            lastChild = transscript.lastElementChild.querySelector(
              ".ac-container"
            )
            if (lastChild) {
              lastChild.scrollIntoView({ behavior: "smooth", block: "start" })
            } else {
              setTimeout(() => {
                lastChild = transscript.lastElementChild.querySelector(
                  ".ac-container"
                )
                if (lastChild) {
                  lastChild.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  })
                }
              }, 50)
            }
          }, 50)
        }
      }
      // lastChild.scrollIntoView({ behavior: "smooth", block: "start" })
      // }, 50)
    }
  } else if (action.type === "DIRECT_LINE/CONNECT_FULFILLED") {
    // When we receive DIRECT_LINE/CONNECT_FULFILLED action, we will send an event activity using WEB_CHAT/SEND_EVENT
    // Interesting alternive, but it is surprisingly slow to get an answer from the bot
    // but the most reliable way to send infos to the bot.
    // Thats why the connection to the bot is established as soon as the gdpr dialog is shown
    // dispatch({
    //   type: "WEB_CHAT/SEND_EVENT",
    //   payload: {
    //     from: {
    //       id: botId,
    //       name: botId,
    //     },
    //     name: "requestWelcomeDialog",
    //     type: "event",
    //     value: "",
    //     channelData: {
    //       personId: botId,
    //       environment: "Webchat",
    //     },
    //   },
    // })
    // NOTE: never use dispatch - slows everything down! - postActivity über directline ist besser
    // dispatch({
    //   type: "WEB_CHAT/SEND_EVENT",
    //   payload: {
    //     from: {
    //       id: botId,
    //       name: botId,
    //     },
    //     type: "event",
    //     name: "setVar",
    //     value: { value: "testvalue", var: "testvar" },
    //   },
    // })
  } else if (action.type === "DIRECT_LINE/POST_ACTIVITY") {
    // console.log(action)
  }
  return next(action)
}

const styleOptions = {
  backgroundColor: "white",
  userAvatarInitials: false,
  botAvatarInitials: true,
  botAvatarBackgroundColor: "#eee",
  botAvatarImage: "/avatar.svg",
  hideSendBox: true,
  hideUploadButton: true,
  primaryFont:
    '"SF Pro Text",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif',
  bubbleFromUserTextColor: "white",
  bubbleTextColor: "#293754",
  bubbleFromUserBackground: "#394d75",
  paddingRegular: 0,
  paddingWide: 0,
  hideScrollToEndButton: true,
  typingAnimationDuration: 60000,
}

export default function Chat(props) {
  const [directLine, setDirectline] = useState(
    createDirectLine({
      token,
      domain,
      webSocket: true,
    })
  )
  const [store, setStore] = useState(createStore({}, storeMiddleware))
  const [usePolling, setUsePolling] = useState(false)

  const classes = useStyles()

  // _____ EFFECTS ____

  useEffect(() => {
    if (directLine !== null)
      directLine
        .postActivity({
          from: {
            id: botId,
            name: botId,
          },
          name: "requestWelcomeDialog",
          type: "event",
          value: "",
          channelData: {
            personId: botId,
            environment: "Webchat",
          },
        })
        .subscribe(function (id) {
          // console.log('"trigger requestWelcomeDialog" sent');
        })
  }, [directLine])

  useEffect(() => {
    // testWebsocketConnection()
  }, [])

  // _____ HANDLERS _____

  function handleRestart() {
    setStore(createStore({}, storeMiddleware))
    setDirectline(() => {
      if (!usePolling) {
        return createDirectLine({
          token,
          domain,
          webSocket: true,
        })
      } else {
        return createDirectLine({
          token,
          domain,
          webSocket: false,
          pollingInterval: 1000,
        })
      }
    })
  }

  function handlePollingFallback() {
    setUsePolling(true)
    setStore(createStore({}, storeMiddleware))
    setDirectline(
      createDirectLine({
        token,
        domain,
        webSocket: false,
        pollingInterval: 1000,
      })
    )
  }

  function testWebsocketConnection() {
    const wsUri = "wss://echo.websocket.org/"
    let websocket = new WebSocket(wsUri)
    websocket.onopen = function (evt) {
      // console.log("OPEN", evt)
      websocket.close()
    }
    websocket.onerror = function (evt) {
      console.log(
        "[Webchat] WSS connection blocked - falling back to endpoint polling"
      )
      handlePollingFallback()
    }
  }

  // _____ MIDDLEWARE _____

  const attachmentMiddleware = () => next => card => {
    if (
      card.attachment.contentType === "application/vnd.microsoft.card.custom"
    ) {
      card.attachment.contentType = "application/vnd.microsoft.card.adaptive"
    }
    return next(card)
  }

  // Control directline connection
  directLine.connectionStatus$.subscribe(connectionStatus => {
    // console.log(connectionStatus)
    if (connectionStatus === 4 && usePolling === false) {
      console.log(
        "[Webchat] WSS connection blocked - falling back to endpoint polling"
      )
      handlePollingFallback()
    }
  })
  // 0 = Uninitialized:    // the status when the DirectLine object is first created/constructed
  // 1 = Connecting:       // currently trying to connect to the conversation
  // 2 = Online:           // successfully connected to the converstaion. Connection is healthy so far as we know.
  // 3 = ExpiredToken:     // last operation errored out with an expired token. Your app should supply a new one.
  // 4 = FailedToConnect, DIRECT_LINE/CONNECT_REJECTED   // the initial attempt to connect to the conversation failed. No recovery possible.
  // 5 = Ended:            // the bot ended the conversation

  // _____ RENDER _____

  return (
    <Dialog
      classes={{
        paper: classes.modalChat,
      }}
      fullWidth={true}
      maxWidth={"md"}
      keepMounted
      open={
        typeof props.open !== "undefined" && props.open !== null
          ? props.open
          : false
      }
      onClose={() => {
        if (props.onClose) {
          props.onClose(false)
        }
      }}
      aria-labelledby="corona-navi-chat-title"
      id="webchat"
    >
      <DialogTitle
        id="corona-navi-chat-title"
        disableTypography
        className={classes.modalHeader}
        style={{ backgroundColor: "white" }}
      >
        <Tooltip title="Chat schließen">
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Schließen"
            color="inherit"
            onClick={() => {
              if (props.onClose) {
                props.onClose(false)
              }
            }}
          >
            <Close className={classes.modalClose} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Neu starten">
          <IconButton
            className={classes.modalCloseButton}
            key="restart"
            aria-label="Neu starten"
            color="inherit"
            onClick={handleRestart}
            style={{ marginRight: "1rem" }}
          >
            <Replay className={classes.modalClose} />
          </IconButton>
        </Tooltip>
        <Text tag="h2" textSize="subheader" textColor="medium">
          Corona Navi
        </Text>
      </DialogTitle>
      <DialogContent id="convaise-webchat-v3" className={classes.modalBody}>
        <ReactWebChat
          attachmentMiddleware={attachmentMiddleware}
          directLine={directLine}
          className={`startFromTop smallAvatar noUserAvatar`}
          styleOptions={styleOptions}
          userID={botId}
          username={botId}
          store={store}
          locale="de-DE"
          style={{
            minWidth: "400px",
            width: "95%",
            position: "fixed",
            height: "95%",
            bottom: "0",
          }}
        />
      </DialogContent>
    </Dialog>
  )
}
